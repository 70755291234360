@import url("https://use.typekit.net/rhs4inp.css");
html, body {
    max-width: 100%;
    overflow-x: hidden;
    ;
}

::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
    /* or add it to the track */
}

::-webkit-scrollbar-thumb {
    background: #081760;
}

/* Reset Styles */

a, a:hover {
    text-decoration: none;
}

ul, ol, dl, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
    margin: 0;
}

/* Custom Classes */

.text-theme {
    color: #3e9cbf !important;
}

a, .transition, #header .navbar li a, #header .navbar li a:after {
    transition: all ease-in-out 0.3s;
}

/* .heading-section h2:hover{animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-name: flickering;
	animation-timing-function: step-start;} 
@keyframes flickering {
    0% {
        color: #28FA2E;
        text-shadow: none;
        transform: skewX(0);
    }
    5% {
        color: #28FA2E;
        text-shadow: none;
        transform: skewX(0);
    }
    10% {
        color: #28FA2E;
        text-shadow: 1px 1px #eee;
        transform: skewX(-15deg);
    }
    15% {
        color: #ccc;
        text-shadow: none;
        transform: skewX(-15deg);
    }
    20% {
        color: #28FA2E;
        text-shadow: none;
        transform: skewX(0);
    }
    25% {
        color: #ccc;
        text-shadow: 2px 2px #28FA2E;
        transform: skewX(0);
    }
    30% {
        color: #ccc;
        text-shadow: 2px 2px #28FA2E;
        transform: skewX(0);
    }
    35% {
        color: #ccc;
        text-shadow: 2px 2px #28FA2E;
        transform: skewX(0);
    }
    37% {
        color: #ccc;
        text-shadow: 1px 1px #eee;
        transform: skewX(0);
    }
    40% {
        color: #28FA2E;
        text-shadow: 1px 1px #eee;
        transform: skewX(0);
    }
    45% {
        color: #28FA2E;
        text-shadow: 1px 1px #eee;
        transform: skewX(0);
    }
    50% {
        color: #28FA2E;
        text-shadow: none;
        transform: skewX(0);
    }
    55% {
        color: #28FA2E;
        text-shadow: none;
        transform: skewX(0);
    }
    60% {
        color: #28FA2E;
        text-shadow: none;
        transform: skewX(0);
    }
    65% {
        color: #28FA2E;
        text-shadow: none;
        transform: skewX(0);
    }
    68% {
        color: #28FA2E;
        text-shadow: 1px 1px #eee;
        transform: skewX(0);
    }
    70% {
        color: #28FA2E;
        text-shadow: 1px 1px #eee;
        transform: skewX(0);
    }
    75% {
        color: #28FA2E;
        text-shadow: 1px 1px #eee;
        transform: skewX(10deg);
    }
    80% {
        color: #28FA2E;
        text-shadow: 1px 1px #eee;
        transform: skewX(10deg);
    }
    85% {
        color: #28FA2E;
        text-shadow: 1px 1px #eee;
        transform: skewX(10deg);
    }
    90% {
        color: #28FA2E;
        text-shadow: none;
        transform: skewX(0);
    }
    95% {
        color: #28FA2E;
        text-shadow: 1px 1px #eee;
        transform: skewX(0);
    }
    100% {
        color: #28FA2E;
        text-shadow: none;
        transform: skewX(0);
    }
    }*/


    /*aqua culture*/
    .aqua-culture {
        padding: 40px;
        padding-top: 80px;
        padding-bottom: 100px;
    }
    .aqua-culture  h2{
        margin: 0;
    color: #000;
    font-size: 40px;
    margin-bottom: 70px;
    font-family: "Oswald";
    position: relative;
    font-weight: 500;
    }
    .aqua-culture .culture-para{
    /* border-radius: 20px; */
    text-align: center;
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    padding: 40px 22px;
    transition: all ease-in-out 0.3s;
    /* border:2px solid #10245d; */
    position: relative;
    overflow: hidden;
   }
   .aqua-culture .culture-para-1{
    background: transparent linear-gradient(180deg, #1BACBF 0%, #3967C4 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
   }
   .aqua-culture .culture-para-2{
    background: transparent linear-gradient(180deg, #A14EC2 0%, #733B96 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
   }
   .aqua-culture .culture-para-3{
    background: transparent linear-gradient(180deg, #F68D1F 0%, #F16A29 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
   }
   .aqua-culture .culture-para:before{
       content:"";
       width:100%;
       height:0;
       position: absolute;
       left:0;
       right:0;
       bottom:0;
       z-index:-1;
       background:#10245d;
       transition: all ease-in-out 0.3s;
   }
   .aqua-culture .culture-para:hover:before{height:100%;}
    /* .blue-bx {
    background-color: #0BFFFE;
    }
    .green-bx{
        background-color: #7AFF08;
    }
    .purple-bx {
        background-color: #F7018F;
    } */
    .aqua-culture .culture-para p {
        /* color: #10245d; */
        color: #fff;
        transition: all ease-in-out 0.3s;
    }
    .aqua-culture .culture-para:hover p {color:#fff;}
    .aqua-culture .culture-para:hover{
       transform: translateY(-5px);
       box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
    }
    /* .aqua-culture .culture-para:hover p{
        color: #f48b1e;
    } */

    /*dalua section*/
    .dalua-main-section{
        position: relative;
    }
    .dalua-image{
        width: 100%;
    }
    .dalua-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .dalua-button {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-40%);
    }
    



    .aqua-flip-card {
        background-color: transparent;
        width: 100%;
        height: 300px;
        perspective: 1000px;
      }
      
      .aqua-flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
      }
      
      .aqua-flip-card:hover .aqua-flip-card-inner {
        transform: rotateY(180deg);
      }
      
      .aqua-flip-card-front, .aqua-flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      
      .aqua-flip-card-front {
        /* background: transparent linear-gradient(180deg, #1BACBF 0%, #3967C4 100%) 0% 0% no-repeat padding-box; */
        border-radius: 40px;
        opacity: 1;
        color: #fff;
        padding: 40px 22px;
      }
      
      .aqua-flip-card-back {
        /* background: transparent linear-gradient(180deg, #1BACBF 0%, #3967C4 100%) 0% 0% no-repeat padding-box; */
        border-radius: 40px;
        opacity: 1;
        color: white;
        padding: 40px 22px;
        transform: rotateY(180deg);
      }

      .network-message{
          padding:15px 10px;
          color:#000;
          background:#f1bd29;
      }

      .background-cover {
        background-size: cover !important;
      }
.btn-refresh-metadata{width:280px;}






    
    @media only screen and (max-width:1600px){
        /*dalua section*/
        .banner-btn {
            padding: 16px 20px;
        }
        .dalua-button {
            bottom: 26px;
        }
    }


@media only screen and (max-width:1399px) {
    .btn-zombie {
        padding: 10px 20px 7px;
    }

    /*dalua section*/
    .banner-btn {
        padding: 12px 16px;
        font-size: 12px;
    }
    .subscribe-btn button{font-size: 16px;}
}

@media only screen and (max-width:1199px) {
    .heading-section h2 span {
        font-size: 60px;
        line-height: 65px;
    }
    .heading-section h2 strong {
        font-size: 70px;
        line-height: 74px;
        margin: -40px 0 0;
    }

    /*aqua culture*/
    .aqua-culture h2, .single-block h2 {
        font-size: 34px;
    }
    .aqua-culture .culture-para p{
        font-size: 14px;
    }

    /*dalua section*/
    .dalua-button {
        bottom: 19px;
        transform: translateX(-42%);
    }
}

@media only screen and (max-width:991px) {
    .pos-stat {
        position: static;
    }

    /*aqua culture*/
    .aqua-culture h2, .single-block h2 {
        font-size: 27px;
    }
    .aqua-culture .culture-para {
        padding: 30px 10px;
    }

    /*dalua section*/
    .banner-btn {
        padding: 10px 14px;
        font-size: 11px;
    }
    .dalua-button {
        bottom: 9px;
    }
    .aqua-flip-card-front, .aqua-flip-card-back{
        padding: 20px 10px;
    }
    .aqua-flip-card-inner p{
        font-size: 14px;
    }
    .aqua-flip-card{
        height: 320px;
    }
}

@media only screen and (max-width:767px) {
    .heading-section h2 span {
        font-size: 50px;
        line-height: 55px;
    }
    .heading-section h2 strong {
        font-size: 50px;
        line-height: 54px;
        margin: -30px 0 0;
    }

    /*aqua culture*/
    .aqua-culture .culture-para {
        margin: 10px 0px;
        height: auto;
        min-height: 165px;
    }
    .aqua-culture h2 {
        font-size: 24px;
    }

    /*dalua section*/
    .dalua-image {
        height: 270px;
    }
    .banner-btn {
        padding: 8px 10px;
        font-size: 10px;
    }
    .aqua-flip-card{
        height: 190px;
        margin-bottom: 16px;
    }
    .aqua-flip-card-front, .aqua-flip-card-back{
        padding: 30px;
    }
    
}

@media only screen and (max-width:575px) {
    .heading-section h2 span {
        font-size: 30px;
        line-height: 35px;
    }
    .heading-section h2 strong {
        font-size: 40px;
        line-height: 45px;
        margin: -20px 0 0;
    }

    /*aqua culture*/
    .aqua-culture .culture-para p {
        font-size: 12px;
    }
    .aqua-culture h2 {
        font-size: 20px;
    }
}

@media only screen and (max-width:480px){

    /*aqua culture*/
    .aqua-culture {
        padding: 0px;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .aqua-flip-card {
        height: 300px;
    }
} 

@media only screen and (max-width:480px){

    /*aqua culture*/
    .aqua-culture h2 {
        font-size: 18px;
    }

}
